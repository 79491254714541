import React from "react";
import ReactCountryFlag from "react-country-flag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import ValidateData from "../../../helpers/validate";
import "./host-header.scss";

type HostHeaderProps = {
  host: any;
};

function HostHeader(props: HostHeaderProps) {
  const isValidCountryCode = ValidateData.isValidCountryCode(props.host.countryCode);

  return (
      <div className="host-header-container">
        <div className="container-left">
          <div className="flag-image">
            {isValidCountryCode ? (
                <ReactCountryFlag
                    className="emojiFlag"
                    countryCode={props.host.countryCode}
                    style={{
                      fontSize: 42,
                      cursor: "pointer",
                    }}
                    aria-label={props.host.countryCode}
                    alt={props.host.countryCode}
                    svg
                />
            ) : (
                props.host.countryCode
            )}
          </div>
          <div className="address-text">{props.host.address}</div>
          <div className="status-indicator">
            <FontAwesomeIcon
                icon={faCircle}
                className={props.host.active ? "active" : "inactive"}
            />
          </div>
          <div className="title-footer">
            v{props.host.version} | {props.host.domain}
          </div>
        </div>
        <div className="container-right">
          <div className="amount">{parseFloat(props.host.accumulatedRewardAmount).toFixed(2)}</div>
          <div className="symbol">EVR</div>
        </div>
      </div>
  );
};

export default HostHeader;
