import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import LeftMenuBar from "../components/left-menu-bar/left-menu-bar";
import geveo_logo from "../assets/images/EV_LOGO.png";
import SettingsHelper from "../helpers/settings-helper";
import NetSelector from "../components/net-selector/net-selector";
import "./layout.scss";
import MomentInfo from "../components/moment-info/moment-info";
import { Networks } from "../helpers/constants";
import HostAddressCell from "../areas/hosts/host-address-cell/host-address-cell";

export default function Layout() {
  return (
    <>
      <div className="layout-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-0 top-bar">
          <div className="container-fluid p-0 m-0">
            <div className="col-md-2 header-bg">
              <Link to="/">
                <img src={geveo_logo} alt="Geveo Australasia" />
              </Link>
            </div>
            <div className="col-md-10 content">
              <div className="row">
                <div className="col-4">
                  <div className="left-content network-name">
                    {SettingsHelper.getSelectedNetwork()}
                  </div>
                </div>
                <div className="col-8 text-right">
                  <div className="right-content">
                    <NetSelector />
                  </div>
                  <div className="right-content">
                    <MomentInfo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 p-0 m-0">
              <div className="sidebar left-menu">
                <LeftMenuBar />
              </div>
            </div>
            <div className="col-md-10 p-0 m-0">
              <div className="main-content page-content">
                <Outlet />
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container-fluid">
            <a
              className="geveo-link"
              href="https://www.geveo.com"
              target="_blank"
              rel="noreferrer"
              title="https://www.geveo.com"
            >
              <p>&copy; 2024 Geveo</p>
            </a>
          </div>
        </footer>
      </div>
    </>
  );
}


