import React, { useState } from "react";
import "./available-leases.scss";

type AvailableLeasesProps = {
  host: any;
}

const AvailableLeases: React.FC<AvailableLeasesProps> = (props: AvailableLeasesProps) => {
  let leases: JSX.Element[] = [];
  for (let index = 0; index < 3; index++) {
    leases.push(
        <div key={index} className="lease-container">
          <div className="lease-token-text">{props.host.uriTokenId}</div>

          <table>
            <tbody>
            <tr>
              <td className="header">Lease Amount</td>
              <td className="value">100</td>
            </tr>
            <tr>
              <td className="header">TOS</td>
              <td className="value">sadasdfsadf</td>
            </tr>
            </tbody>
          </table>
        </div>
    );
  }

  return (
      <div className="available-leases-container">
        <div className="title">Available Leases</div>
        {leases}
      </div>
  );
};

export default AvailableLeases;
