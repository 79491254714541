import "./copy-button.scss";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { faCopy, faCheckDouble } from "@fortawesome/free-solid-svg-icons";

type CopyButtonProps = {
    text: string;
    visibilityClassName?: string;
};


function CopyButton(props:CopyButtonProps){
    const [copied, setCopied] = useState(false);

    const handleCopy = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        console.log(event)
        navigator.clipboard.writeText(props.text);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <div className={`copy-button-container float-right ${props.visibilityClassName}`} onClick={(e) => e.preventDefault()}>
            <Tooltip title={copied ? "Copied" : "Copy to clipboard"} className="ms-1 copy-icon-tooltip">
                <div className="copy-button" onClick={handleCopy}>
                    <FontAwesomeIcon
                        icon={copied ? faCheckDouble : faCopy}
                        className={"m-1 copy-button " + (copied ? "done-all" : "")}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default CopyButton;
