import React from "react";
import PageTitle from "../../../components/page-title/page-title";
import "./not-found.scss";

export default function NotFoundError() {
    return (
      <>
        <PageTitle title="Page Not Found" />
        <div className="page-body-content">
          <h3>Unknow page requests, please check the URL.</h3>
        </div>
      </>
    );

}
