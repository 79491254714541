import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import GridView from "../../components/gridview/gridview";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import PageTitle from "../../components/page-title/page-title";
import { Networks } from "../../helpers/constants";
import SettingsHelper from "../../helpers/settings-helper";
import HostAddressCell from "./host-address-cell/host-address-cell";
import "./hosts.scss";
import CopyButton from "../../components/copy-button/copy-button";
import {faFloppyDisk, faMemory, faMicrochip, faSatelliteDish} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CountryFlag from "../../../src/components/CountryFlag/index";
import {useSelector, useDispatch} from "react-redux";
import {addHostList, hostList} from "../../redux/slices/hostListSlice";
import {RootState} from "../../redux/store";

import { Modal } from "@material-ui/core";
import HostModal from "../../components/host-modal/host-modal";

function Hosts() {

  const dispatch = useDispatch();
  const hostLs = useSelector((state:RootState) => state.hostList.hosts);
  const currentNetwork = useSelector((state: RootState )=> state.currentNetwork);

  const [selectedNetwork , setSelectedNetwork] = useState(null)
  const [hosts, setHosts] = useState<any>([]);
  const [isCopyBtnVissible, setIsCopyBtnVisible] = useState(true)
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    let apiUrl = "";
    if (currentNetwork === Networks.testNet) {
      apiUrl = "https://dapps-dev7.geveo.com/api/download/hostlist/testnet";
    } else {
      apiUrl = "https://dapps-dev7.geveo.com/api/download/hostlist/mainnet";
    }

    if(hostLs.length == 0 || selectedNetwork !== currentNetwork) {
        fetch(apiUrl)
            .then((res) => res.json())
            .then((data) => {
              // add 'id' field
              data.forEach((node: { id: any; key: any }) => (node.id = node.key));
              setHosts(data);
              dispatch(addHostList(data));
              setSelectedNetwork(currentNetwork);
            })
            .catch((err) => {
              console.log(err.message);
            });

    } else {
        setHosts(hostLs)
    }
  }, [currentNetwork]);

    function getTimeElapsedString(timestamp: number) {
        const currentTime = Math.floor(Date.now() / 1000); // Convert current time to Unix timestamp in seconds
        const elapsedTime = currentTime - timestamp;

        if (elapsedTime < 60) {
            return `${elapsedTime} seconds ago`;
        } else if (elapsedTime < 3600) {
            const minutes = Math.floor(elapsedTime / 60);
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else if (elapsedTime < 86400) {
            const hours = Math.floor(elapsedTime / 3600);
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else {
            const days = Math.floor(elapsedTime / 86400);
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        }
    }

    const onRowHover = (rowData: any) => {
        // console.log(rowData)
    }

    const onRowClick = (rowData: any) => {
        setSelectedRowData(rowData);
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen(false);
      };

    const hostActiveRenderCell = (params: any) => {
        return (
            <div
                className={
                    "node-status-cell " +
                    (params.data.active ? "active-node" : "inactive-node")
                }
            >
                <FontAwesomeIcon icon={faSatelliteDish} />
                <p style={{fontSize: '8px', margin: '0px'}}>{getTimeElapsedString(params.data.lastHeartbeatIndex)}</p>
            </div>
        );
    };


    const instanceDetailsRenderCell = (params: any) => {
        let cpu = params.data.cpuMicrosec;
            let ram = params.data.ramMb;
            let disk = params.data.diskMb;
            let instanceCount = params.data.maxInstances;

            const specs = [];
            if (instanceCount) {
                if (cpu) specs.push(`${Math.round(cpu / 10000 / instanceCount)}% CPU`);
                if (ram) specs.push(`${Math.round(ram / 1000 / instanceCount)}GB RAM`);
                if (disk)
                    specs.push(`${Math.round(disk / 1000 / instanceCount)}GB Disk`);
            }

            const text = specs.join(", ");

            return (
                <>
                    <div style={{padding: '0px', margin: '0px', fontSize: '10px', height: '15px', textAlign: 'center'}}><FontAwesomeIcon icon={faMicrochip} /> {specs[0]}</div>
                    <div style={{padding: '0px', margin: '0px', fontSize: '10px', height: '15px', textAlign: 'center'}}><FontAwesomeIcon icon={faMemory} /> {specs[1]}</div>
                    <div style={{padding: '0px', margin: '0px', fontSize: '10px', height: '15px', textAlign: 'center'}}><FontAwesomeIcon icon={faFloppyDisk} /> {specs[2]}</div>
                </>
            );
    }

    const addressRenderCell = (params: any) => {
        return (
            <>
                <div style={{height: '15px', fontSize:'12px', fontWeight: '500', display: 'flex'}}><span>{params.data.address}</span> <CopyButton visibilityClassName={"visible-on-row-hover"}  text={params.data.address}/></div>
                <div style={{height: '15px'}}>{params.data.domain}</div>
                <div style={{height: '15px'}}>{params.data.version}v</div>
            </>
        );
    }

    const flagRenderCell = (params: any) => {

        return (
            <CountryFlag countryCode={params.data.countryCode} size="3em" />
        );
    }

    const instanceCountRenderCell = (params: any) => {
        const activeCount = params.data.activeInstances;
        const maxInstances = params.data.maxInstances;

        return (
            <p style={{ margin: '0'}}>
                {activeCount > -1 ? <span style={{fontWeight: '700', fontSize: '12px'}}>{activeCount} /</span> : <span>{activeCount} /</span>} {maxInstances}
            </p>
        )
    }

    const columns: any[] = [
        {
            headerName: "",
            headerClass: "table-header",
            cellClass: "cell-style justify-content-center ",
            width: 50,
            cellRenderer: flagRenderCell
        },
        {
            field: 'address',
            headerName: "Host",
            filter: true,
            floatingFilter: false,
            suppressHeaderFilterButton: true,
            headerClass: "table-header",
            cellClass: "cell-style flex-vertical",
            width: 340,
            sortable: true,
            headerTooltip: 'Host Info',
            cellRenderer: addressRenderCell
      },
      {
          field: "active",
          headerName: "Status",
          headerClass: "table-header justify-content-center",
          cellClass: "cell-style justify-content-center flex-vertical",
          width: 75,
          sortable: true,
          headerTooltip: 'Active/Inactive',
          cellRenderer: hostActiveRenderCell
      },
      {
          field: "hostReputation",
          headerName: "Reputation",
          headerClass: "table-header justify-content-center",
          cellClass: "cell-style justify-content-center",
          sortable: true,
          width: '120',
          filter: true,
          floatingFilter: false,
          cellClassRules: {
              'text-center': (params: any) => true,
              'red-background': (params: any) => params.value < 200
          }

      },
        {
            headerName: "Instance Size",
            headerClass: "table-header",
            cellClass: "cell-style flex-vertical",
            width: 110,
            sortable: false,
            cellRenderer: instanceDetailsRenderCell
        },
        {
            headerName: "Cost (EVR/1hr)",
            valueGetter: (p: any) => p.data.leaseAmount ? Number(p.data.leaseAmount) : 0,
            headerClass: "table-header",
            cellDataType: 'number',
            sortable: true,
            width: 120,
            cellClass: "cell-style justify-content-center",
            filter: true,
            headerTooltip: 'Price of an instance per hour',
            floatingFilter: false,

        },
      {
          field: 'activeInstances',
          headerName: "Instances Count",
          headerClass: "table-header",
          width: 120,
          cellClass: "cell-style justify-content-center",
          // cellRenderer: instanceCountRenderCell,
          children: [
              {
                  field: "activeInstances",
                  headerName: "Active",
                  headerClass: "table-header",
                  width: 80,
                  sortable: true,
                  cellClass: "cell-style justify-content-center",
              },
              {
                  field: "maxInstances",
                  headerName: "Total",
                  headerClass: "table-header",
                  width: 80,
                  sortable: true,
                  cellClass: "cell-style justify-content-center",
              }
          ],
          
      },
        {
            field: "cpuModelName",
            headerName: "CPU Model",
            valueGetter: (p:any) => p.data.cpuModelName + ', ' + p.data.cpuCount + ' cores',
            headerClass: "table-header",
            cellClass: "cell-style justify-content-flex-start",
            width: 250,
            wrapText: true,
            sortable: false,
            
        },
  ];

  return (
      <div className="hosts">
        <PageTitle title="Hosts" />

        {hosts.length > 0 ? (
            <>
            <GridView
                key={hosts.id}
                columns={columns}
                dataset={hosts}
                allowCheckboxSelection={false}
                pageSizeOptions={[10, 20, 50, 100]}
                headerRowHeight={45}
                rowHeight={65}
                onRowHover={onRowHover}
                onRowClicked={onRowClick}
            />  
          <HostModal isOpen={isModalOpen} onClose={handleCloseModal} rowData={selectedRowData} />
            </>
        ) : (
            <center>
              <LoadingSpinner size="3rem" />
            </center>
        )}
      </div>
  );
};

export default Hosts;