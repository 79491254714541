import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./areas/dashboard/dashboard";
import NotFoundError from "./areas/errors/not-found/not-found";
import HostLookup from "./areas/host-lookup/host-lookup";
import Hosts from "./areas/hosts/hosts";
import Registry from "./areas/registry/registry";
import TestnetFaucet from "./areas/testnet-faucet/testnet-faucet";
import Layout from "./layouts/layout";
import Home from "./components/home/home";

export default function AppRoutes() {

    const BrowserRoutes = () => {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/hosts" element={<Hosts />} />
              <Route path="/host-lookup" element={<HostLookup />} />
              <Route path="/registry" element={<Registry />} />
              <Route path="/testnet-faucet" element={<TestnetFaucet />} />
              <Route path="/*" element={<NotFoundError />} />
            </Route>
          </Routes>
        </BrowserRouter>
      );
    };

    return <BrowserRoutes />;
}
