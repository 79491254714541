import React, {useEffect, useState} from "react";
import "./moment-info.scss";
import EvernodeService from "../../services/EvernodeService";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

type MomentInfoProps = {
  environment?: any;
  config?: any;
  ledger?: any;
};

function MomentInfo(props: MomentInfoProps){
  const evernodeService = EvernodeService.getInstance();
  const currentNetwork = useSelector((state: RootState) => state.currentNetwork);

  const [config, setConfig] = useState<any>(null);
  const [ledgerMainnet, setLedgerMainnet] = useState<any>(null);
  const [ledgerTestnet, setLedgerTestnet] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)  ;
    evernodeService.switchEnvironment(currentNetwork).then(() => {
      evernodeService.onLedger((e: any) => {
        if(currentNetwork === 'mainnet'){
          setLedgerMainnet(e);
        }

        if(currentNetwork === 'testnet') {
          setLedgerTestnet(e);
        }
        setIsLoading(false)
      }).then(res => {
        // console.log(res)
      });

    })



    return () => {

    };
  }, [currentNetwork]);

  return currentNetwork === 'mainnet'
      ? (
            <div className="row m-0 mt-2 moment-info">
              <div className="col-9">
                <div className="label">Last closed ledger ({currentNetwork})</div>
                {isLoading
                    ?
                    <LoadingSpinner size={'1rem'}/>
                    :
                    <div className="ledger-count">{ledgerMainnet ? ledgerMainnet.ledgerIndex : 0}</div>
                }
              </div>

              <div className="moment-container col-3">
                <div className="label">Moment</div>
                {isLoading
                    ? <LoadingSpinner size={'1rem'}/>
                    : <div>{ledgerMainnet ? ledgerMainnet.moment : 0}</div>
                }
              </div>
            </div>
      )
      :
      (
            <div className="row m-0 mt-2 moment-info">
              <div className="col-9">
                <div className="label">Last closed ledger ({currentNetwork})</div>
                {isLoading
                    ?
                    <LoadingSpinner size={'1rem'}/>
                    :
                    <div className="ledger-count">{ledgerTestnet ? ledgerTestnet.ledgerIndex : 0}</div>
                }
              </div>

              <div className="moment-container col-3">
                <div className="label">Moment</div>
                {isLoading
                    ? <LoadingSpinner size={'1rem'}/>
                    : <div>{ledgerTestnet ? ledgerTestnet.moment : 0}</div>
                }
              </div>
            </div>
      )
};

export default MomentInfo;

