export class LocalStorageKeys {
  static selectedNetwork = "selectedNetwork";
  static selectedHostAddress = "selectedHostAddress";
}

export class Networks {
  static mainNet = "mainnet";
  static testNet = "testnet";
}

export class NetworkAPIs {
  // fetch("https://dapps-dev7.geveo.com/download/updated_host_list.json") // old json file
  // fetch("https://dapps-dev7.geveo.com/api/data/hostlist/mainnet") // with pagination ?page=1&limit=10

  static mainNetApi = "https://dapps-dev7.geveo.com/api/";
  static testNetApi = "https://dapps-dev7.geveo.com/api/";
}
