import React, { useEffect, useState } from "react";
import PageTitle from "../../components/page-title/page-title";
// import { useEvernode } from "../../services/evernode-client-service";
import "./registry.scss";
import EvernodeService from "../../services/EvernodeService";
import LoadingIndicator from "../../components/loading-indicator/loading-indicator";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Networks} from "../../helpers/constants";

type RegistryProps = {
  governorAddress?: string;
  configuration?: any;
};
type RegistryState = {};


const Registry = () => {
  const [configuration, setConfiguration] = useState<any>(null);
  const evernodeService = EvernodeService.getInstance();
  const currentNetwork = useSelector((state: RootState )=> state.currentNetwork);
  const registryConfigs = useSelector((state: RootState )=> state.registryConfigs);

  useEffect(() => {
    if(currentNetwork === Networks.mainNet) {
      setConfiguration(registryConfigs.mainnet.configs)
    } else if(currentNetwork === Networks.testNet) {
      setConfiguration(registryConfigs.testnet.configs)
    }

  }, [currentNetwork, registryConfigs]);

  return (
      <>
        { configuration !== null ? <div className="registry-container">
          <PageTitle title="Configurations" />

          {/* Configurations */}
          <div>
            <table className="table-container">
              <tbody>
              <tr>
                <td className="header">Governor Hook Address</td>
                <td className="value">{configuration && registryConfigs[currentNetwork].governorAddress}</td>
              </tr>
              <tr>
                <td className="header">Heartbeat Hook Address</td>
                <td className="value">
                  {configuration
                      ? configuration.heartbeatAddress
                      : ""}
                </td>
              </tr>
              <tr>
                <td className="header">Registry Hook Address</td>
                <td className="value">
                  {configuration
                      ? configuration.registryAddress
                      : ""}
                </td>
              </tr>
              </tbody>
            </table>
            <table className="table-container mt-5">
              <tbody>
              <tr>
                <td className="header">EVR Issuer Xahau Address</td>
                <td className="value">
                  {configuration
                      ? configuration.evrIssuerAddress
                      : ""}
                </td>
              </tr>
              <tr>
                <td className="header">Evernode Labs Xahau Address</td>
                <td className="value">
                  {configuration
                      ? configuration.foundationAddress
                      : ""}
                </td>
              </tr>
              <tr>
                <td className="header">Heartbeat Frequency</td>
                <td className="value">
                  {configuration
                      ? configuration.hostHeartbeatFreq
                      : ""}
                </td>
              </tr>
              <tr>
                <td className="header">Registration Fee</td>
                <td className="value">
                  {configuration
                      ? `${configuration.hostRegFee} EVR`
                      : ""}
                </td>
              </tr>
              <tr>
                <td className="header">Lease Acquire Window</td>
                <td className="value">
                  {configuration
                      ? configuration.leaseAcquireWindow
                      : ""}
                </td>
              </tr>

              {configuration && configuration.momentBaseInfo &&
              configuration.momentBaseInfo ? (
                  <>
                    <tr>
                      <td className="header">Moment Base Timestamp</td>
                      <td className="value">
                        {configuration
                            ? new Date(
                                configuration.momentBaseInfo.baseIdx
                            ).toLocaleString()
                            : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="header">Transition Moment</td>
                      <td className="value">
                        {configuration
                            ? configuration.momentBaseInfo
                                .baseTransitionMoment
                            : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="header">Moment Size</td>
                      <td className="value">
                        {configuration
                            ? configuration.momentSize
                            : 0}
                        &nbsp;
                        {configuration.momentBaseInfo.momentType ===
                        "ledger"
                            ? "ledgers"
                            : "seconds"}
                      </td>
                    </tr>
                  </>
              ) : (
                  ""
              )}
              <tr>
                <td className="header">Host Count</td>
                <td className="value">
                  {configuration
                      ? configuration.hostCount
                      : 0}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          {/* Reward Configurations */}
          <div>
            <div>
              <table className="table-container mt-5">
                <tbody>
                <tr>
                  <td className="value py-1">
                    <h2>Reward Configurations</h2>
                  </td>
                  <td className="value"></td>
                </tr>
                <tr>
                  <td className="header">Epoch Count</td>
                  <td className="value">
                    {configuration
                        ? configuration.rewardConfiguration
                            .epochCount
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header">Epoch Reward Amount</td>
                  <td className="value">
                    {configuration
                        ? `${configuration.rewardConfiguration.epochRewardAmount} EVR`
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header">First Epoch Reward Quota</td>
                  <td className="value">
                    {configuration
                        ? configuration.rewardConfiguration
                            .firstEpochRewardQuota
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header">Reward Start Moment</td>
                  <td className="value">
                    {configuration
                        ? configuration.rewardConfiguration
                            .rewardStartMoment
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header rounded-0">
                    Host Reputation Threshold
                  </td>
                  <td className="value rounded-0">
                    {configuration
                        ? configuration.rewardConfiguration
                            .hostReputationThreshold
                        : 0}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table className="table-container">
                <tbody>
                <tr>
                  <td className="value py-1 rounded-0">
                    <h2>Reward Info</h2>
                  </td>
                  <td className="value rounded-0"></td>
                </tr>
                <tr>
                  <td className="header">Current Epoch</td>
                  <td className="value">
                    {configuration &&
                    configuration.rewardInfo
                        ? configuration.rewardInfo.epoch
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header">Epoch Pool</td>
                  <td className="value">
                    {configuration &&
                    configuration.rewardInfo
                        ? (+configuration.rewardInfo
                            .epochPool).toFixed(3)
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header">
                    Active Host Count of the Moment{" "}
                    {configuration &&
                    configuration.rewardInfo
                        ? configuration.rewardInfo.savedMoment
                        : ""}
                  </td>
                  <td className="value">
                    {configuration &&
                    configuration.rewardInfo
                        ? configuration.rewardInfo
                            .curMomentActiveHostCount
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header">
                    Active Host Count of the Moment{" "}
                    {configuration &&
                    configuration.rewardInfo
                        ? configuration.rewardInfo.savedMoment - 1
                        : ""}
                  </td>
                  <td className="value">
                    {configuration &&
                    configuration.rewardInfo
                        ? configuration.rewardInfo
                            .prevMomentActiveHostCount
                        : 0}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Governance Configurations */}
          <div>
            <div>
              <table className="table-container mt-5">
                <tbody>
                <tr>
                  <td className="value py-1">
                    <h2>Governance Configurations</h2>
                  </td>
                  <td className="value"></td>
                </tr>
                <tr>
                  <td className="header">Candidate Election Period</td>
                  <td className="value">
                    {configuration
                        ? configuration.governanceConfiguration
                            .candidateElectionPeriod
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header">Candidate Life Period</td>
                  <td className="value">
                    {configuration
                        ? configuration.governanceConfiguration
                            .candidateLifePeriod
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header">Candidate Support Average</td>
                  <td className="value">
                    {configuration
                        ? configuration.governanceConfiguration
                            .candidateSupportAverage
                        : 0}
                  </td>
                </tr>
                <tr>
                  <td className="header rounded-0">Eligibility Period</td>
                  <td className="value rounded-0">
                    {configuration
                        ? configuration.governanceConfiguration
                            .eligibilityPeriod
                        : 0}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table className="table-container">
                <tbody>
                <tr>
                  <td className="value py-1 rounded-0">
                    <h2>Governance Info</h2>
                  </td>
                  <td className="value rounded-0"></td>
                </tr>
                <tr>
                  <td className="header">Elected Proposal Unique Id</td>
                  <td className="value">
                    {configuration &&
                    configuration.governanceInfo &&
                    !configuration.governanceInfo.electedProposalUniqueId.match(
                        /^0+$/
                    )
                        ? configuration.governanceInfo
                            .electedProposalUniqueId
                        : ""}
                  </td>
                </tr>
                <tr>
                  <td className="header">Proposal Elected Timestamp</td>
                  <td className="value">
                    {configuration &&
                    configuration.governanceInfo &&
                    configuration.governanceInfo
                        .proposalElectedTimestamp > 0
                        ? new Date(
                            configuration.governanceInfo.proposalElectedTimestamp
                        ).toLocaleString()
                        : ""}
                  </td>
                </tr>
                <tr>
                  <td className="header">Governance Mode</td>
                  <td className="value">
                    {configuration &&
                    configuration.governanceInfo
                        ? configuration.governanceInfo.governanceMode.toUpperCase()
                        : ""}
                  </td>
                </tr>
                <tr>
                  <td className="header">Last Candidate Idx</td>
                  <td className="value">
                    {configuration &&
                    configuration.governanceInfo
                        ? configuration.governanceInfo
                            .lastCandidateIdx
                        : ""}
                  </td>
                </tr>
                <tr>
                  <td className="header">Vote Base Count</td>
                  <td className="value">
                    {configuration &&
                    configuration.governanceInfo
                        ? configuration.governanceInfo.voteBaseCount
                        : ""}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        : <LoadingIndicator />}
      </>
  );
};

export default Registry;

