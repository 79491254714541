import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./home.scss"
import SettingsHelper from "../../helpers/settings-helper";
import { Networks } from "../../helpers/constants";
import HostAddressCell from "../../areas/hosts/host-address-cell/host-address-cell";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import LoadingSpinner from "../loading-spinner/loading-spinner";

const Home = () => {
    const currentNetwork = useSelector((state: RootState )=> state.currentNetwork);

    const [hosts, setHosts] = useState([]);
    const [activeHosts, setActiveHosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        let apiUrl = "";
        if (currentNetwork === Networks.testNet) {
            apiUrl = "https://dapps-dev7.geveo.com/api/download/hostlist/testnet";
        } else {
            apiUrl = "https://dapps-dev7.geveo.com/api/download/hostlist/mainnet";
        }

        fetch(apiUrl)
            .then((res) => res.json())
            .then((data) => {
                // add 'id' field
                data.forEach((node: { id: any; key: any }) => (node.id = node.key));
                const activeHosts = data.filter((host: { active: any; }) => host.active);
                setActiveHosts(activeHosts);
                setHosts(data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [HostAddressCell, currentNetwork]);



    return (
        <div className="home-container">
    <div className="write-container">
      <div className="card">
        <h3>Active Hosts</h3>
          {isLoading ? <LoadingSpinner size={'1rem'}/> : <p>{activeHosts.length}</p>}
      </div>
        <div className="card">
            <h3>Total Hosts</h3>
            {isLoading ? <LoadingSpinner size={'1rem'}/> : <p>{hosts.length}</p>}
        </div>
            {/* <div className="textbox-container">
        <input type="text" placeholder="" className="text-box" />
        <div className="arrow">➡️</div>
      </div> */}
        </div>
    </div>
            );
};

export default Home;
