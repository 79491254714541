import React from "react";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import "./loading-indicator.scss";

export default function LoadingIndicator(props: any) {
  return (
    <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
      <div className="w-50 mx-auto">
        <LoadingSpinner size="5rem" />
      </div>
    </div>
  );
}
