import React, {useEffect} from "react";
import AppRoutes from "./app-routes";
import "./App.scss";
// import { EvernodeProvider } from "./services/evernode-client-service";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useDispatch} from "react-redux";
import SettingsHelper from "./helpers/settings-helper";
import {switchNetwork} from "./redux/slices/selectedNetworkSlice";

export default function App() {
    const dispatch = useDispatch();

    // useEffect(() => {
    //     const cNet = SettingsHelper.getSelectedNetwork();
    //     dispatch(switchNetwork(cNet));
    // }, [])

    return (
      <>
          <AppRoutes />
      </>
    );
}
