import { Alert } from "@mui/material";
import React, { useState } from "react";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import PageTitle from "../../components/page-title/page-title";
import FaucetBox from "./faucet-box/faucet-box";
import "./testnet-faucet.scss";
import EvernodeService from "../../services/EvernodeService";

type TestnetFaucetProps = {
  buttonClicked: boolean;
};
type TestnetFaucetState = {};

function TestnetFaucet(props: any) {
    const evernodeService = EvernodeService.getInstance();
    const [faucetBox, setFaucetBox] = useState({});
    const [accountGeneratedFlag, setAccountGeneratedFlag] = useState(false);
    const [accountButtonClick, setAccountButtonClick] = useState(false);
    const [warningDisplay, setWarningDisplay] = useState(false);

    const testnetFaucet = () => {
      setAccountButtonClick(true);
      setAccountGeneratedFlag(false);

        evernodeService
        .testnetFaucet()
        .then((accountDetails: any) => {
          setWarningDisplay(false);
          setFaucetBox(accountDetails);
          if (
            accountDetails !== undefined &&
            typeof accountDetails !== "undefined"
          ) {
            setAccountGeneratedFlag(true);
            setAccountButtonClick(false);
          }
        })
        .catch((e: any) => {
          console.log(e);
          setWarningDisplay(true);
          setAccountButtonClick(false);
        });
    };



    return (
      <>
        <PageTitle title="Testnet Faucet" />
        <div className="testnet-faucet-container">
          <button
            className="btn btn-primary mb-4"
            disabled={accountButtonClick}
            onClick={testnetFaucet}
          >
            Generate an account
          </button>
          {accountButtonClick ? (
            <center>
              <LoadingSpinner size="3rem" />
            </center>
          ) : null}
          {accountGeneratedFlag ? <FaucetBox faucetBox={faucetBox} /> : null}
          {warningDisplay ? (
            <Alert severity="warning">Try again shortly!</Alert>
          ) : null}
        </div>
      </>
    );
}

export default TestnetFaucet;

