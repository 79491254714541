import { faWindowRestore } from "@fortawesome/free-regular-svg-icons";
import {
  faGear,
  faLandmark,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LocalStorageKeys } from "../../helpers/constants";
import SettingsHelper from "../../helpers/settings-helper";
import "./left-menu-bar.scss";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

type LeftMenuBarProps = {
  route?: string;
};
type LeftMenuBarState = {};

export default function LeftMenuBar() {

  const currentNetwork = useSelector((state: RootState )=> state.currentNetwork);
  const [route, setRoute] = useState('')

  const getCurrentRoute = () => {
    // this.setState({ route: SettingsHelper.getCurrentRoute() });
  }

  useEffect(() => {
    getCurrentRoute();
  })

  return (
    <>
      <div className="left-menu-bar">
        {/* <div className="menu-item">
          <Link
            to="/dashboard"
            onClick={() => {
              setTimeout(() => {
                this.getCurrentRoute();
              }, 100);
            }}
          >
            <div
              className={
                "content " + (this.state.route === "/dashboard" ? "selected" : "")
              }
            >
              <FontAwesomeIcon icon={faGauge} className="menu-icon" />
              Dashboard
            </div>
          </Link>
        </div> */}

        
        <div className="menu-item">
          <Link
            to="/home"
            onClick={() => {
              setTimeout(() => {
                getCurrentRoute();
              }, 100);
            }}
          >
            <div
              className={
                "content " + (route === "/home" ? "selected" : "")
              }
            >
              <FontAwesomeIcon icon={faWindowRestore} className="menu-icon" />
              Home
            </div>
          </Link>
        </div>

        <div className="menu-item">
          <Link
            to="/hosts"
            onClick={() => {
              setTimeout(() => {
                getCurrentRoute();
              }, 100);
            }}
          >
            <div
              className={
                "content " + (route === "/hosts" ? "selected" : "")
              }
            >
              <FontAwesomeIcon icon={faWindowRestore} className="menu-icon" />
              Hosts
            </div>
          </Link>
        </div>

        <div className="menu-item">
          <Link
            to="/registry"
            onClick={() => {
              SettingsHelper.removeLocalStorageKey(
                LocalStorageKeys.selectedHostAddress
              );
              setTimeout(() => {
                getCurrentRoute();
              }, 100);
            }}
          >
            <div
              className={
                "content " +
                (route === "/registry" ? "selected" : "")
              }
            >
              <FontAwesomeIcon icon={faGear} className="menu-icon" />
              Registry
            </div>
          </Link>
        </div>
        {currentNetwork === 'testnet'
            ? (
                <div className="menu-item">
                  <Link
                      to="/testnet-faucet"
                      onClick={() => {
                        SettingsHelper.removeLocalStorageKey(
                            LocalStorageKeys.selectedHostAddress
                        );
                        setTimeout(() => {
                          getCurrentRoute();
                        }, 100);
                      }}
                  >
                    <div
                        className={
                            "content " +
                            (route === "/testnet-faucet" ? "selected" : "")
                        }
                    >
                      <FontAwesomeIcon icon={faLandmark} className="menu-icon" />
                      Testnet Faucet
                    </div>
                  </Link>
                </div>

            ) : (
                <></>
            )
        }


        {/* <div className="menu-item">
          <Link to="/candidates">
            <div className="content">
            <FontAwesomeIcon icon={faLightbulb} className="menu-icon" />
            Candidates
            </div>
          </Link>
        </div> */}
      </div>
    </>
  );


}
