import { LocalStorageKeys, NetworkAPIs, Networks } from "./constants";
import {store} from "../redux/store";
import {switchNetwork} from "../redux/slices/selectedNetworkSlice";

export default class SettingsHelper {
  static removeLocalStorageKey(key: string) {
    window.localStorage.removeItem(key);
  }

  static setSelectedNetwork(selectedNetwork: string) {
    localStorage.setItem(
      LocalStorageKeys.selectedNetwork,
      selectedNetwork
    );

    store.dispatch(switchNetwork(selectedNetwork));
  }

  static getSelectedNetwork() {
    let selectedValue = window.localStorage.getItem(
      LocalStorageKeys.selectedNetwork
    );

    if (selectedValue) {
      if (selectedValue === Networks.mainNet) {
        return Networks.mainNet;
      }
      return Networks.testNet;
    }
    return Networks.mainNet; // default
  }

  static setSelectedHostAddress(selectedHostAddress: string) {
    window.localStorage.setItem(
      LocalStorageKeys.selectedHostAddress,
      selectedHostAddress
    );
  }

  static getSelectedHostAddress() {
    let selectedValue = window.localStorage.getItem(
      LocalStorageKeys.selectedHostAddress
    );

    if (selectedValue) {
      return selectedValue;
    }
    return selectedValue;
  }

  static getCurrentNetowkApiUrl() {
    if (SettingsHelper.getSelectedNetwork() === Networks.testNet) {
      return NetworkAPIs.testNetApi;
    } else {
      return NetworkAPIs.mainNetApi;
    }
  }

  static getCurrentRoute() {
    let path = window.location.pathname;
    return path;
  }
}
