import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import SettingsHelper from "../../helpers/settings-helper";
import GetHostAddressPopup from "./get-host-address/get-host-address";
import HostHeader from "./host-header/host-header";
import HostRegistrationInfo from "./host-reg-info/host-reg-info";
import "./host-lookup.scss";
import AvailableLeases from "./available-leases/available-leases";
import { Modal } from "@material-ui/core";

const HostLookup = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [hostAddress, setHostAddress] = useState("");
  const [host, setHost] = useState(null);
  const [getAddress, setGetAddress] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let hostAddress = SettingsHelper.getSelectedHostAddress();

      if (!hostAddress) {
        setGetAddress(true);
        return;
      }

      setShowLoader(true);

      let network = SettingsHelper.getSelectedNetwork().toLocaleLowerCase();
      let url = SettingsHelper.getCurrentNetowkApiUrl();

      try {
        const res = await fetch(`${url}data/host/${network}/${hostAddress}`);
        const data = await res.json();
        if (data) {
          setHost(data);
          setShowLoader(false);
          SettingsHelper.setSelectedHostAddress(data.address);
        } else {
          setGetAddress(true);
        }
      } catch (error) {
        console.log(error);
        setShowLoader(false);
      }
    };

    fetchData();
  }, []);

  const handleCloseModal = () => {
    setGetAddress(false);
  };

  return (
    <Modal open={getAddress} onClose={handleCloseModal}>
      <div className="host">
        {getAddress ? (
          <GetHostAddressPopup
            open={true}
            onClose={handleCloseModal}
            hostAddress=""
            onSubmit={(data: any) => {
              if (/^r[a-zA-Z0-9]{24,34}$/g.test(data.hostAddress)) {
                SettingsHelper.setSelectedHostAddress(data.hostAddress);
                window.location.reload();
              }
              return false;
            }}
          />
        ) : null}

        {host !== null ? (
          <>
            <HostHeader host={host} />
            <div className="row">
              <div className="col-6">
                <div className="host-reg-info-container">
                  <HostRegistrationInfo host={host} />
                </div>
              </div>
              <div className="col-6">
                <div className="available-leases-container">
                  <AvailableLeases host={host} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <center>{showLoader ? <LoadingSpinner size="3rem" /> : null}</center>
        )}
      </div>
    </Modal>
  );
};

export default HostLookup;
