// EvernodeService.js
import ECDSA from 'xrpl/dist/npm/ECDSA';
import { store } from './../redux/store'
import {Networks} from "../helpers/constants";
import {addMainnetCongfigs, addTestnetCongfigs} from "../redux/slices/registryConfigSlice";


const xrpl = require("xrpl");
const evernode = require("evernode-js-client");

class EvernodeService {
    constructor() {
        this.xrplApi = null;
        this.governorClient = null;
        this.xrplApis = {};
        this.environment = process.env.REACT_APP_DEFAULT_NETWORK;
        this.governorClients = {}
    }

    // Singleton pattern
    static getInstance() {
        if (!this.instance) {
            this.instance = new EvernodeService();
        }
        return this.instance;
    }

    async connect() {

        await evernode.Defaults.useNetwork(this.environment);
        // Additional setup based on environment (if needed)
        // For example:
        if (this.environment === 'mainnet') {
            evernode.Defaults.set({
                rippledServer: 'wss://xahau.network'
            });
        }
        // Connect to XRPL
        if (!this.xrplApis[this.environment]) {
            this.xrplApis[this.environment] = new evernode.XrplApi(evernode.Defaults.values.rippledServer);
            this.xrplApi = this.xrplApis[this.environment];
            await this.xrplApi.connect();
        }

        // Connect to governor client
        if(!this.governorClients[this.environment]) {
            this.governorClients[this.environment] = await evernode.HookClientFactory.create(evernode.HookTypes.governor);
            this.governorClient = this.governorClients[this.environment]
            await this.governorClients[this.environment].connect();

            const configs = await this.governorClient.config;
            if(this.environment === Networks.mainNet){
                store.dispatch(addMainnetCongfigs({
                    properties: {
                        governorConnected: true,
                        governorAddress: this.governorClient.governorAddress,
                        configs: configs
                    }
                }))
            } else if (this.environment === Networks.testNet) {
                store.dispatch(addTestnetCongfigs({
                    properties: {
                        governorConnected: true,
                        governorAddress: this.governorClient.governorAddress,
                        configs: configs
                    }
                }))
            }
        }

    }

    async switchEnvironment(newEnvironment) {
        this.environment = newEnvironment;

        if(!this.xrplApis[newEnvironment]) {
            await this.connect();
        } else {
            this.xrplApi = this.xrplApis[newEnvironment];
            this.governorClient = this.governorClients[newEnvironment];
        }

        return true;
    }

    // Add methods for interacting with Evernode
    async getConfigs() {
        if(this.governorClient && this.governorClient.connected) {
            const configs = await this.governorClient.config;
            return configs;
        }

        return null;
    }

    async getDefinitions() {
        return await evernode.Defaults.values;
    }

    getHosts(filters = null, pageSize = null, nextPageToken = null) {
        return this.governorClient.getHosts(filters, pageSize, nextPageToken);
    }

    getHostInfo(hostAddress)  {
        return this.governorClient.getHostInfo(hostAddress);
    }

    getCandidates(filters = null, pageSize = null, nextPageToken = null)  {
        return this.governorClient.getCandidates(filters, pageSize, nextPageToken);
    }

    async getCandidateById(candidateId)  {
        return this.governorClient.getCandidateById(candidateId);
    }

    async getCandidateType(candidateId)  {
        return evernode.StateHelpers.getCandidateType(candidateId);
    }

    async decodeLeaseUri(uri)  {
        return evernode.UtilHelpers.decodeLeaseTokenUri(uri);
    }

    async getDudHostCandidatesByOwner(address)  {
        return this.governorClient.getDudHostCandidatesByOwner(address);
    }

    async getCandidateByOwner(address)  {
        return this.governorClient.getCandidateByOwner(address);
    }

    async getLeases(address)  {
        const client = new evernode.HostClient(address);
        await client.connect();

        const leases = await client.xrplAcc.getURITokens();
        const leaseTokens = leases.filter(n => evernode.EvernodeHelpers.isValidURI(n.URI, evernode.EvernodeConstants.LEASE_TOKEN_PREFIX_HEX))

        return leaseTokens.map(leaseToken => {
            return {
                uriTokenId: leaseToken.index,
                uri: leaseToken.URI
            }
        });
    }

    async getEVRBalance(address) {
        const client = new evernode.HostClient(address, null, {xrpl: this.xrplApi});
        await client.connect();

        return await client.getEVRBalance();
    }

    async onLedger(callback) {
        this.xrplApi.on(evernode.XrplApiEvents.LEDGER, async (e) => {

            if(this.governorClients[this.environment]) {
                this.governorClient.getMoment().then(moment => {
                    callback({
                        ledgerIndex: e.ledger_index,
                        moment: moment
                    })
                }).catch(e => {
                    console.log(e)
                });
            }
        });
    }

    async testnetFaucet(rippledServer='wss://hooks-testnet-v3.xrpl-labs.com') {

        const generatedAccount = await this.generateAndFundFaucetAccount(rippledServer);
        return generatedAccount;
    }

    async generateAndFundFaucetAccount(rippledServer){
        // Generating faucet account
        const new_wallet = xrpl.Wallet.generate(ECDSA.secp256k1);

        await fetch(`http${rippledServer.substring(2)}/newcreds?account=${new_wallet.address}`, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                "Content-Type": "application/json"
            }
        });

        console.log("11")

        const xrplClient = new xrpl.Client(rippledServer);
        await xrplClient.connect();

        console.log("22")

        // Keep watching until XAHs are received.
        let attempts = 0;
        while (attempts >= 0) {
            await new Promise(solve => setTimeout(solve, 1000));
            const balance = await xrplClient.getXrpBalance(new_wallet.address).catch(e => {
                if (e.message !== 'Account not found.')
                    throw e;
            });

            if (!balance) {
                if (++attempts <= 20)
                    continue;
                throw Error("XAH funds not received within timeout.");
            }
            break;
        }

        const hostClient = new evernode.HostClient(new_wallet.address, new_wallet.seed);
        await hostClient.connect();

        console.log("Requesting beta EVRs...");

        await hostClient.xrplAcc.setTrustLine(evernode.EvernodeConstants.EVR, hostClient.config.evrIssuerAddress, "99999999999999");

        await hostClient.xrplAcc.makePayment(hostClient.config.foundationAddress,
            evernode.XrplConstants.MIN_XRP_AMOUNT,
            evernode.XrplConstants.XRP,
            null,
            [{ type: 'giftBetaEvr', format: '', data: '' }]);

        // Keep watching our EVR balance.
        attempts = 0;
        while (attempts >= 0) {
            await new Promise(solve => setTimeout(solve, 1000));
            const balance = await hostClient.getEVRBalance();

            if (balance === '0') {
                if (++attempts <= 20)
                    continue;
                throw Error("EVR funds not received within timeout.");
            }
            return {
                address: new_wallet.address,
                secret: new_wallet.seed,
                xrp: await xrplClient.getXrpBalance(new_wallet.address),
                evrBalance: balance,
            };
        }
    }

    // Add more methods as needed


}

export default EvernodeService;
