import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import "./get-host-address.scss";

type GetHostAddressPopupProps = {
  hostAddress: string;
  open: boolean;
  onClose: () => void;
  onSubmit?: (formData: any) => void;
};

function GetHostAddressPopup (props: GetHostAddressPopupProps) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson: any = Object.fromEntries(formData.entries());
    const email = formJson.email;
    console.log(email);
    if (props.onSubmit) {
      props.onSubmit(formJson);
    }
  };

  return (
      <Dialog
          className="get-host-address-dialog"
          open={props.open}
          onClose={props.onClose}
          PaperProps={{
            component: "form",
          }}
      >
        <DialogTitle>Host Address</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Enter the host Xahau address:</DialogContentText> */}
          <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="hostAddress"
              label="Enter the host Xahau address"
              type="text"
              fullWidth
              variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
  );
};

export default GetHostAddressPopup;
