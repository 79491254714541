import React from "react";
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'; // AG Grid Component
import "./gridview.scss";

type Props = {
  columns?: any[];
  dataset: any[];
  pageSizeOptions: number[];
  allowCheckboxSelection: boolean;
  headerRowHeight?: number;
  rowHeight?: number;
  onRowClicked?: any;
  onRowHover?: any;
}

export default function GridView(props: Props) {

    const pagination = true;
    const paginationPageSize = 10;
    const paginationPageSizeSelector = [10,20, 50, 100, 200, 500, 1000];

    // useEffect(() => {
    //     // console.log(props.dataset)
    // }, []);

    const onRowHover = (event: any) => {
        props.onRowHover(event.data);
    }

    const onRowClicked = (event: any) => {
        if(event.event.defaultPrevented) {  // This prevents any icon clicks inside the row
            return null;
        }
        props.onRowClicked(event.data);
    }


  return (
      <div
          className="ag-theme-alpine" // applying the grid theme
          style={{}} // the grid will fill the size of the parent container
      >
          <AgGridReact
              rowData={props.dataset}
              columnDefs={props.columns}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              suppressRowHoverHighlight={false}
              rowClass={'row-style'}
              rowHeight={props.rowHeight}
              onCellMouseOver={onRowHover}
              onRowClicked={onRowClicked}
              suppressMenuHide={true}
              domLayout='autoHeight'
          />
      </div>



      // <div className="gridview-container">
      //   <DataGrid
      //     rows={props.dataset}
      //     columns={props.columns}
      //     initialState={{
      //       pagination: {
      //         paginationModel: {
      //           page: 0,
      //           pageSize: props.pageSizeOptions[0],
      //         },
      //       },
      //     }}
      //     pageSizeOptions={props.pageSizeOptions}
      //     checkboxSelection={props.allowCheckboxSelection}
      //     rowSelection={false}
      //     columnHeaderHeight={props.headerRowHeight}
      //     rowHeight={props.rowHeight}
      //     onRowClick={props.onRowClick}
      //   />
      //
      //   {props.dataset.length === 0 ? <p>No hosts found!</p> : <></>}
      // </div>
  );
}
