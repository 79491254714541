import React from "react";
import { Modal } from "@material-ui/core";
import "./host-modal.scss";
import HostRegistrationInfo from "../../areas/host-lookup/host-reg-info/host-reg-info";
import AvailableLeases from "../../areas/host-lookup/available-leases/available-leases";
import HostHeader from "../../areas/host-lookup/host-header/host-header";

interface HostModalProps {
    isOpen: boolean;
    onClose: () => void;
    rowData: any;
}

const HostModal: React.FC<HostModalProps> = ({ isOpen, onClose, rowData }) => {

    const address = rowData ? rowData.address : "";
    const hostData = rowData || {};

    function getInstanceSize(host: any) {
        let cpu = host.cpuMicrosec;
        let ram = host.ramMb;
        let disk = host.diskMb;
        let instanceCount = host.maxInstances;

        const specs = [];
        if (instanceCount) {
            if (cpu) specs.push(`${round(cpu / 10000 / instanceCount)}% CPU`);
            if (ram) specs.push(`${round(ram / 1000 / instanceCount)}GB RAM`);
            if (disk) specs.push(`${round(disk / 1000 / instanceCount)}GB Disk`);
        }

        const text = specs.join(", ");
        return text ? text : "-";
    }

    function round(n: number) {
        return Math.round(n * 100) / 100;
    }

    console.log("hostData", hostData)
    return (
        <Modal open={isOpen} onClose={onClose}>
            <div className="modal-content">
                <div className="modal-title">
                <HostHeader host={hostData} />
                <hr/>
                </div>
                <div className="row">
                    <div className="col-sm-7">
                    <HostRegistrationInfo host={hostData} />
                    </div>
                    <div className="col-sm-5">
                    {/* Accordion */}
                    <AvailableLeases host={hostData} />
                    </div>
                </div>
                <button className="close-button" onClick={onClose}>X</button>
            </div>
        </Modal>
    );
};

export default HostModal;
