import {configureStore } from '@reduxjs/toolkit';
import { hostListReducer } from './slices/hostListSlice';
import {currentNetworkReducer} from './slices/selectedNetworkSlice'
import {registryConfigsReducer} from './slices/registryConfigSlice'

export const store: any = configureStore({
    reducer: {
        hostList: hostListReducer,
        currentNetwork: currentNetworkReducer,
        registryConfigs: registryConfigsReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;