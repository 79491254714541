import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import type { PayloadAction } from "@reduxjs/toolkit";


type TinitialStateType = string;

const initialState: TinitialStateType = 'mainnet';



export const selectedNetworkSlice = createSlice({
    name: "currentNetwork",
    initialState,
    reducers: {
        switchNetwork: (state, action: PayloadAction<string>) => {
            return action.payload;
        },
        switchToMainnet: (state) => {
            return 'mainnet';
        }
    }
});

export const {switchNetwork, switchToMainnet} = selectedNetworkSlice.actions;
export const currentNetwork = (state: RootState) => state;
export const currentNetworkReducer =  selectedNetworkSlice.reducer;


