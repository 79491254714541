import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import type { PayloadAction } from "@reduxjs/toolkit";


type TinitialStateType = {
    hosts: any[];
};

const initialState: TinitialStateType = {
    hosts: []
}



export const hostListSlice = createSlice({
    name: "hostList",
    initialState,
    reducers: {
        addHostList: (state, action: PayloadAction<any[]>) => {
            state.hosts = action.payload;
        },
        clearHostList: (state) => {
            state.hosts = [];
        }
    }
});

export const {addHostList, clearHostList} = hostListSlice.actions;
export const hostList = (state: RootState) => state.hostList;
export const hostListReducer =  hostListSlice.reducer;


