import React, {useState} from "react";
import CopyButton from "../../../components/copy-button/copy-button";
import "./faucet-box.scss";

type FaucetBoxProps = {
  faucetBox: any;
};

export default function FaucetBox(props: FaucetBoxProps) {
  const [addressCopied, setAddressCopied] = useState(false)

    return (
      <>
        <div className="faucet-box-container">
          <table className="table-container">
            <tbody>
              <tr>
                <td className="header">Address</td>
                <td className="value">
                  <div className="float-left">
                    {props.faucetBox.address}
                  </div>
                  <CopyButton text={props.faucetBox.address} />
                </td>
              </tr>
              <tr>
                <td className="header">Secret</td>
                <td className="value">
                  <div className="float-left">
                    {props.faucetBox.secret}
                  </div>
                  <CopyButton text={props.faucetBox.secret} />
                </td>
              </tr>
              <tr>
                <td className="header">XAH</td>
                <td className="value">{props.faucetBox.xrp}</td>
              </tr>
              <tr>
                <td className="header">EVR</td>
                <td className="value">{props.faucetBox.evrBalance} EVR</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
}
