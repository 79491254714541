import React from "react";
import "./dashboard.scss";

export default function Dashboard() {
    return (
      <center>
        <h2>I'm Dashboard</h2>
      </center>
    );

}
