import React, {useEffect} from "react";
import { LocalStorageKeys, Networks } from "../../helpers/constants";
import SettingsHelper from "../../helpers/settings-helper";
// import {useEvernode} from '../../services/evernode-client-service'
import EvernodeService from "../../services/EvernodeService";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

let styles = {
  container: {
    
  },
  ddl: {
    backgroundColor: "white",
    minWidth: "100px",
    padding: "18px",
    borderRadius: "5px",
    fontSize: "1rem",
  },
};

// function saveNetwork(selectedNetwork: string) {
//   SettingsHelper.setSelectedNetwork(selectedNetwork);
//   SettingsHelper.removeLocalStorageKey(LocalStorageKeys.selectedHostAddress);
//
//   if (SettingsHelper.getCurrentRoute() === "/hosts") {
//     window.location.reload();
//   } else {
//     window.location.href = "/hosts";
//   }
// }

export default function NetSelector() {
  const evernodeService = EvernodeService.getInstance();
  const currentNetwork = useSelector((state: RootState )=> state.currentNetwork)

  useEffect(() => {

  }, [currentNetwork])

  const saveNetwork = async (selectedNetwork: string) => {
    SettingsHelper.setSelectedNetwork(selectedNetwork);
    SettingsHelper.removeLocalStorageKey(LocalStorageKeys.selectedHostAddress);
    await evernodeService.switchEnvironment(selectedNetwork)

  }

  return (
    <>
      <div style={styles.container} className="mt-2">
        <select
          style={styles.ddl}
          onChange={(e) => {
            saveNetwork(e.target.value.toString());
          }}
          value={currentNetwork}
        >
          <option value={Networks.mainNet}>MAINNET</option>
          <option value={Networks.testNet}>TESTNET</option>
        </select>
      </div>
    </>
  );
}

