import React from "react";
import "./page-title.scss";

export default function PageTitle(props: any) {
  return (
    <>
      <div className="page-title-container">
        <h2>{props.title}</h2>
      </div>
    </>
  );
}

