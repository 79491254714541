import React from "react";
import "./host-reg-info.scss";

type HostRegistrationInfoProps = {
  host: any;
};
type HostRegistrationInfoState = {};

export default class HostRegistrationInfo extends React.Component<
  HostRegistrationInfoProps,
  HostRegistrationInfoState
> {

  render(): React.ReactNode {
    return (
      <>
        <div className="host-reg-info">
          <div className="title">Registration Info</div>
          <table className="table-container">
            <tbody>
              <tr>
                <td className="header">Registration Token Id</td>
                <td className="value token-id">{this.props.host.uriTokenId}</td>
              </tr>
              <tr>
                <td className="header">Host Email</td>
                <td className="value">{this.props.host.email}</td>
              </tr>
              <tr>
                <td className="header">Instances</td>
                <td className="value">
                  <table>
                    <tbody>
                      <tr>
                        <td className="inner-table-header">To Let</td>
                        <td className="inner-table-header">Leased</td>
                        <td className="inner-table-header">Total</td>
                      </tr>
                      <tr>
                        <td className="inner-table-value">
                          {this.props.host.maxInstances -
                            this.props.host.activeInstances}
                        </td>
                        <td className="inner-table-value">
                          {this.props.host.activeInstances}
                        </td>
                        <td className="inner-table-value">
                          {this.props.host.maxInstances}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="header">CPU Model</td>
                <td className="value">{this.props.host.cpuModelName}</td>
              </tr>
              <tr>
                <td className="header">Instance Size</td>
                <td className="value">{getInstanceSize(this.props.host)}</td>
              </tr>
              <tr>
                <td className="header">Last Heartbeat Index</td>
                <td className="value">{this.props.host.lastHeartbeatIndex}</td>
              </tr>

              <tr>
                <td className="header">Registered on Xahau Ledger</td>
                <td className="value">{this.props.host.registrationLedger}</td>
              </tr>
              <tr>
                <td className="header">Registration Fee</td>
                <td className="value">{this.props.host.registrationFee} EVR</td>
              </tr>
              <tr>
                <td className="header">Version</td>
                <td className="value">{this.props.host.version}</td>
              </tr>
              <tr>
                <td className="header">Registered on Timestamp</td>
                <td className="value">
                  {this.props.host.registrationTimestamp > 0
                    ? new Date(
                        this.props.host.registrationTimestamp
                      ).toLocaleString()
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="header">Accumulated Reward Amount</td>
                <td className="value">
                  {this.props.host.accumulatedRewardAmount} EVR
                </td>
              </tr>
              <tr>
                <td className="header">Last Vote Timestamp</td>
                <td className="value">
                  {this.props.host.lastVoteTimestamp > 0
                    ? new Date(this.props.host.lastVoteTimestamp).toLocaleString()
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="header">Host Reputation</td>
                <td className="value">{this.props.host.hostReputation}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

function getInstanceSize(host: any) {
  let cpu = host.cpuMicrosec;
  let ram = host.ramMb;
  let disk = host.diskMb;
  let instanceCount = host.maxInstances;

  const specs = [];
  if (instanceCount) {
    if (cpu) specs.push(`${round(cpu / 10000 / instanceCount)}% CPU`);
    if (ram) specs.push(`${round(ram / 1000 / instanceCount)}GB RAM`);
    if (disk) specs.push(`${round(disk / 1000 / instanceCount)}GB Disk`);
  }

  const text = specs.join(", ");
  return text ? text : "-";
}

function round(n: number) {
  return Math.round(n * 100) / 100;
}
