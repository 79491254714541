import React from "react";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import CopyButton from "../../../components/copy-button/copy-button";
import SettingsHelper from "../../../helpers/settings-helper";
import ValidateData from "../../../helpers/validate";
import "./host-address-cell.scss";

type HostAddressCellProps = {
    row: any;
};

function HostAddressCell(props:HostAddressCellProps) {
    const isValidCountryCode = ValidateData.isValidCountryCode(props.row.countryCode);

    return (
        <>
            <div className="flag-image" title={isValidCountryCode ? props.row.countryCode : ""}>
                {isValidCountryCode ? (
                    <ReactCountryFlag
                        className="emojiFlag"
                        countryCode={props.row.countryCode}
                        style={{
                            fontSize: 32,
                            cursor: "pointer",
                            width: "unset"
                        }}
                        aria-label={props.row.countryCode}
                        alt={props.row.countryCode}
                        svg
                    />
                ) : (
                    props.row.countryCode
                )}
            </div>
            <div className="node-address">
                <div className="address-text">
                    <Link
                        to={"/host-lookup"}
                        onClick={() => {
                            SettingsHelper.setSelectedHostAddress(props.row.address);
                        }}
                    >
                        {props.row.address}
                    </Link>
                </div>
                <CopyButton text={props.row.address} />
                <div className="node-address-footer">
                    v{props.row.version} | {props.row.domain}
                </div>
            </div>
        </>
    );
};

export default HostAddressCell;
