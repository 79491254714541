import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import type { PayloadAction } from "@reduxjs/toolkit";


type TinitialStateType = {
    mainnet: {
        governorConnected: boolean,
        governorAddress: string,
        configs: any
    },
    testnet: {
        governorConnected: boolean,
        governorAddress: string,
        configs: any
    }
};

const initialState: TinitialStateType = {
    mainnet: {
        governorConnected: false,
        governorAddress: '',
        configs: null
    },
    testnet: {
        governorConnected: false,
        governorAddress: '',
        configs: null
    }
};



export const registryConfigsSlice = createSlice({
    name: "registryConfigs",
    initialState,
    reducers: {
        addMainnetCongfigs: (state, action: PayloadAction<{properties: any}>) => {
            const newobj = {...state};
            newobj.mainnet = {
                governorConnected: action.payload.properties.governorConnected,
                governorAddress: action.payload.properties.governorAddress,
                configs: action.payload.properties.configs
            }
            return newobj;
        },
        addTestnetCongfigs: (state, action: PayloadAction<{properties: any}>) => {
            const newobj = {...state};
            newobj.testnet = {
                governorConnected: action.payload.properties.governorConnected,
                governorAddress: action.payload.properties.governorAddress,
                configs: action.payload.properties.configs
            }
            return newobj;
        },
    }
});

export const {addMainnetCongfigs, addTestnetCongfigs} = registryConfigsSlice.actions;
export const registryConfigs = (state: RootState) => state;
export const registryConfigsReducer =  registryConfigsSlice.reducer;


